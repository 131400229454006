.head {
    z-index: 10;
    width: 100%;
    height: 0vh;
    position: fixed;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.head.active {
    height: 100vh;
    z-index: 100;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    background-color: #ED5B46;
}

.nav-items {
    color: #edfdf8;
    opacity:0;
    text-align: left;
    position: absolute;
    left: 15%;
    width: 100%;
    margin-top:20%;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    height: 0%;
}

.nav-items a {
    display: none;
    height: 0;
}

.nav-items.active a {
    color: #f7e06c;
    text-decoration: underline;
    height: 100%;
    display: inline-block
}

.nav-items a:hover {
    color: #f5e7a0;
    text-decoration: overline;
}

.nav-items h2 {
    margin: 0;
    font-size: 0;
    text-shadow: 2px 2px 2px #53241c;
}

.nav-items p {
    font-size: 1.6rem;
    margin: 0;
    text-shadow: 1px 1px 1px #53241c;
}

.nav-items.active {
    opacity: 1;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    z-index: 100;
}

.nav-items.active h2 {
    font-size: 2rem;
    line-height: 10%;
    margin: 2.3733333333rem 0 1.424rem 0;
}

.nav-col {
    float: left;
    margin: 27px 140px 20px 0;
    height: 0%;
}

.nav-items.active .nav-col {
    height: fit-content;
}

.row.nav-icons-row {
    height: 100%;
    margin-top: 0px;
}

.nav-items.active ion-icon {
    color: #f7e06c;
  }

.nav-items.active ion-icon:hover {
    color: #f5e7a0;
}

.nav-bg {
    height: 50px;
    width: 50px;
    background-color: #ED5B46;
    top: 10px;
    right: 10px;
    position: absolute;
    border-radius: 30%;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-out; 
    transition: all 0.3s ease-out; 
}

.nav-bg.active {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.nav-wrap {
    height: 50px;
    width: 50px;
    background-color: #ED5B46;
    top: 10px;
    right: 10px;
    position: absolute;
    border-radius: 30%;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.nav-wrap.active {
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    background-color: #ED5B46;
}

.nav-div {
    height: 50px;
    width: 50px;
    background-color: transparent;
    top: 10px;
    right: 10px;
    position: absolute;
    border-radius: 30%;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    display: block;
}

.bar {
    background-color: white;
    height: 2px;
    border-radius: 5px;
    right: 12px;
    top: 14px;
}

.bar.first {
    position: absolute;
    width: 30px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.bar.second {
    position: absolute;
    margin-top: 10px;
    width: 30px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.bar.third {
    position: absolute;
    margin-top: 20px;
    width: 30px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.bar.first.active {
    margin-top: 13px;
    transform-origin: 50% 50%;
    transform: rotate(45deg);
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.bar.second.active {
    opacity: 0;
    margin-top: 7px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.bar.third.active {
    margin-top: 13px;
    width: 30px;
    transform-origin: 50% 50%;
    transform: rotate(-45deg);
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

@media only screen and (min-width: 650px) {
    
    .nav-items.active h2 {
        font-size: 3.56rem;
        line-height: 110%;
    }

    .nav-items p {
        font-size: 1.8rem;
    }

    .nav-col a {
        margin-top: 10px;
    }

    .nav-col-contact {
        margin-right: 7%;
    }

    .nav-col-icons {
        margin-top: 100px;
    }

}